import { Controller } from '@hotwired/stimulus';
import Swiper, { Navigation } from 'swiper/swiper-bundle.min.js';

export default class extends Controller {
  static targets = ['prev', 'next'];
  connect() {
    Swiper.use([Navigation]);
    const carousel = new Swiper(this.element, {
      slidesPerView: 1.2,


      breakpoints: {
        800: {
          slidesPerView: 2,
          spaceBetween:20,
        },
      },

      navigation: {
        nextEl: this.nextTarget,
        prevEl: this.prevTarget,
      },
    });
  }
}
