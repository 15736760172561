import { Controller } from "@hotwired/stimulus";

const freeze = () => {
  window.Turbo.navigator.currentVisit.scrolled = true;
  document.removeEventListener("turbo:render", freeze);
};

const freezeScrollOnNextRender = () => {
  document.addEventListener("turbo:render", freeze);
};

export default class extends Controller {
  static targets = ["intentHiddenInput"];

  connect() {}

  resetAll(e) {
    e.preventDefault();
    this.intentHiddenInputTarget.value = "reset";
    this.submitForm();
  }

  resetFilters(e) {
    e.preventDefault();
    this.intentHiddenInputTarget.value = "reset-filters";
    this.submitForm();
  }

  selectFilter() {
    this.intentHiddenInputTarget.value = "update-filter";
    this.submitForm();
  }

  submitForm() {
    freezeScrollOnNextRender();
    Rails.fire(this.element, "submit");
  }
}
