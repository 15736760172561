import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    state: { type: String, default: "closed" },
  };

  escToClose(e) {
    if (e.key === "Escape") {
      this.close();
      this.closeDropdowns();
    }
  }

  toggleDropdown(e) {
    e.preventDefault();
    const ACTIVE_CLASS = "active-dropdown";
    const dropdown = document.querySelector(e.params.dropdownSelector);
    if (dropdown == undefined) return;
    if (dropdown.classList.contains(ACTIVE_CLASS)) {
      dropdown.classList.remove(ACTIVE_CLASS);
    } else {
      const activeDropdowns = document.querySelectorAll(`.${ACTIVE_CLASS}`);
      [...activeDropdowns].forEach((active) =>
        active.classList.remove(ACTIVE_CLASS)
      );
      dropdown.classList.add(ACTIVE_CLASS);
    }
  }

  toggleNav() {
    ["links-open", "filter-open"].includes(this.stateValue)
      ? this.close()
      : (this.stateValue = "links-open");
  }

  toggleFilter() {
    this.stateValue = "filter-open";
  }

  closeDropdowns() {
    const ACTIVE_CLASS = "active-dropdown";
    const activeDropdowns = document.querySelectorAll(`.${ACTIVE_CLASS}`);
    [...activeDropdowns].forEach((active) =>
      active.classList.remove(ACTIVE_CLASS)
    );
  }

  close() {
    this.stateValue = "closed";
  }
}
