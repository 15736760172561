import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    title: String,
    url: String,
  };

  static targets = ['alert'];

  promptShare(e) {
    e.preventDefault();
    e.stopPropagation();

    const shareData = {
      title: this.titleValue,
      url: this.urlValue,
    };

    if (navigator.canShare && navigator.canShare(shareData)) {
      navigator.share(shareData).catch((err) => console.error(err));
    } else {
      this.copyToClipboard(shareData.url);
    }
  }

  copyToClipboard(content) {
    navigator.clipboard.writeText(content);
    this.alertTarget.setAttribute('aria-hidden', 'false');
    setTimeout(
      () => this.alertTarget.setAttribute('aria-hidden', 'true'),
      2000,
    );
  }
}
