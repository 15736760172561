import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input'];

  connect() {
    this.checkInput();
  }

  checkInput() {
    this.labelSelected(this.inputTarget);
  }

  labelSelected(select) {
    if (select == undefined) { return }

    this.element.classList.toggle('selected', select.selectedIndex > 0);
  }
}
