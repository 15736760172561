import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["toToggle"];
  static classes = ["toggled"];
  toggle(e) {
    e.preventDefault();
    this.toToggleTarget.classList.toggle(this.toggledClass);
  }
}
