import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    environment: String,
  };

  connect() {
    const KEYS = {
      development: {
        portal: 'vLfyJ',
        survey: '0ffaf957-9dda-4120-964a-536e6dc8c376',
      },
      staging: {
        portal: 'wscva',
        survey: '35012ae9-f99d-407e-b04f-6b2fa7764b02',
      },
      production: {
        portal: 'wkMAK',
        survey: 'e3252776-9e23-4779-8980-46d5783ac1f0',
      },
    };

    [...document.querySelectorAll('[data-appzi-toggle]')].forEach((toggle) => {
      toggle.onclick = (e) => {
        e.preventDefault;
        window.appzi && window.appzi.openWidget(KEYS[this.environmentValue]['survey'])
      }
    })

    if (window && window.appzi == undefined) {
      window.appziSettings = {
        portal: KEYS[this.environmentValue]['portal'],
      };

      window.appziReboot = () => {
        fetch(`https://api.appzi.io/api/w/${KEYS[this.environmentValue]['survey']}`)
          .then((resp) => resp.json())
          .then((config) => {
            appzi.destroy(KEYS[this.environmentValue]['survey']);
            appzi.create(config);
          });
      };

      const script = document.createElement('script');
      script.src = `https://w.appzi.io/w.js`;
      document.body.appendChild(script);
    } else {
      window.appziReboot();
    }
  }
}
