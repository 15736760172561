import { application } from "./application";

import SearchController from "./search_controller";
import ShareController from "./share_controller";
import SupportCarouselController from "./support_carousel_controller";
import DelegateFocusController from "./delegate_focus_controller";
import ToggleController from "./toggle_controller";
import SelectController from "./select_controller";
import HeaderController from "./header_controller";
import AppziController from "./appzi_controller";

application.register("search", SearchController);
application.register("support-carousel", SupportCarouselController);
application.register("share", ShareController);
application.register("delegate-focus", DelegateFocusController);
application.register("toggle", ToggleController);
application.register("select", SelectController);
application.register("header", HeaderController);
application.register("appzi", AppziController);
